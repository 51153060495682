<template>
  <div class="base-timer d-flex">
    Te queda <b class="time text-center">{{ timer }}</b> para completar tu compra.
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    expiresTime: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      timer: '',
    }
  },
  computed: {
    ...mapGetters('tickets', ['cart']),
  },
  mounted() {
    // Set the date we're counting down to
    const countDownTime = new Date(this.cart[0].expiresAt).getTime()
    // Update the count down every 1 second
    const x = setInterval(() => {
      // Get today's date and time
      const now = new Date().getTime()
      // Find the distance between now and the count down date
      const distance = countDownTime - now
      // Time calculations for days, hours, minutes and seconds
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      const seconds = Math.floor((distance % (1000 * 60)) / 1000)
      // Output the result in an element with id="demo"
      this.timer = `${minutes}m:${seconds}s`
      // If the count down is over, write some text
      if (distance < 0) {
        clearInterval(x)
        this.timer = 'Tiempo finalizado'
      }
    }, 1000)
  },
  methods: {

  },
}
</script>

<style scoped lang="scss">
@import '~@core/scss/base/core/colors/palette-variables.scss';
.base-timer {
  font-size: 13px;
}
.time {
  width: 55px;
}
</style>

<template>
  <b-nav-item-dropdown
    no-caret
    right
    class="dropdown-cart mr-25"
    menu-class="dropdown-menu-media"
    @show="getUserCart({ userId: currentUser.id })"
  >
    <template #button-content>
      <feather-icon
        id="cartIcon"
        :badge="totalTickets"
        class="cart-icon alt-color"
        :class="bgwhite ? 'text-primary':''"
        icon="ShoppingCartIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li>
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Carrito
        </h4>
        <b-badge
          pill
          variant="light-primary"
        >
          {{ totalTickets }}
        </b-badge>
      </div>
    </li>

    <!-- Cart Items -->
    <vue-perfect-scrollbar
      v-show="cart.length"
      :settings="perfectScrollbarSettings"
      class="p-1"
      tagname="li"
    >
      <b-media
        v-for="item in cart"
        :key="`raffle-${item.raffleId}`"
        class="itemInCart mb-1"
      >
        <template #aside>
          <b-img
            :src="item.img"
            :alt="item.raffleName"
            rounded
            width="62px"
          />
        </template>
        <div class="media-heading">
          <h6 class="cart-item-title">
            <b-link class="text-body">
              {{ item.raffleName }}
            </b-link>
          </h6>
        </div>

        <b-badge
          v-for="ticket in item.tickets"
          :key="`ticket-${ticket.id}`"
          class="cart-item-qty ml-1"
          variant="primary"
        >
          {{ ticket.folio }}
        </b-badge>

        <h5 class="cart-item-price">
          ${{ totalOfRaffle(item.tickets) }}
        </h5>
      </b-media>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li
      v-show="cart.length"
      class="dropdown-menu-footer"
    >
      <div class="d-flex justify-content-between mb-1">
        <h6 class="font-weight-bolder mb-0">
          Total:
        </h6>
        <h6 class="text-primary font-weight-bolder mb-0">
          ${{ totalAmount }}
        </h6>
      </div>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        :to="{ name: 'cart-checkout' }"
      >
        Pagar
      </b-button>
      <div class="d-flex justify-content-center align-items-center mt-1 px-1">
        <cart-timer
          v-if="cart[0]"
          class="timer"
          :expires-time="cart[0].expiresAt"
        />
      </div>
    </li>

    <p
      v-if="!cart.length"
      class="m-0 p-1 text-center"
    >
      Tu carrito está vacio
    </p>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BImg, BButton,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import { mapGetters, mapActions } from 'vuex'
import CartTimer from '@/@core/components/CartTimer.vue'

export default {
  components: {
    CartTimer,
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BImg,
    VuePerfectScrollbar,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    bgwhite: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
    }
  },
  computed: {
    ...mapGetters('tickets', ['cart']),
    ...mapGetters('auth', ['currentUser']),
    totalTickets() {
      let tickets = 0
      this.cart.forEach(raffle => {
        tickets += raffle.tickets.length
      })
      return tickets
    },
    totalAmount() {
      let total = 0
      this.cart.forEach(raffle => {
        raffle.tickets.forEach(ticket => { total += ticket.price })
      })
      return total
    },
  },
  mounted() {
    window.addEventListener('scroll', () => {
      this.scrollPosition = window.scrollY
      if (this.scrollPosition >= 25) {
        document.getElementById('cartIcon').classList.remove('alt-color')
      } else {
        document.getElementById('cartIcon').classList.add('alt-color')
      }
    })
  },
  methods: {
    ...mapActions('tickets', ['checkout', 'getUserCart']),
    totalOfRaffle(currentRaffle) {
      let raffleTotal = 0
      currentRaffle.forEach(ticket => { raffleTotal += ticket.price })
      return raffleTotal
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/core/colors/palette-variables.scss';

.timer  {
  position: relative;
}

.dropdown-header {
  min-width: 418px;
  height: 44px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ebe9f1;
}
.dropdown-menu-footer {
  padding: 1.28rem;
  border-top: 1px solid #ebe9f1;
}

.dropdown-cart {
  position: relative;
  .media {
    position: relative;
    img {
      background: $body-bg;
    }
    &:hover {
      .cart-item-remove {
        visibility: visible;
      }
    }
  }
  .media-heading {
    width: 8rem;
    h6.cart-item-title {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .cart-item-by {
      color: $text-muted;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  .media-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .cart-item-price {
    margin-bottom: 0;
    width: 5rem;
    text-align: right;
  }

  .cart-item-remove {
    position: absolute;
    top: 0;
    right: 0;
    width: 14px;
    height: 14px;
    cursor: pointer;
    @include media-breakpoint-up(md) {
      visibility: hidden;
    }
  }
}
.itemInCart:last-child {
  margin-bottom: 0 !important;
}

.alt-color {
  color: white;
}
</style>
